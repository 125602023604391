import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Collapse, Nav, NavItem, NavLink as BootstrapNavLink} from 'reactstrap';
import {NavLink, withRouter} from 'react-router-dom';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import {useContext} from 'react';
import AppContext, {AuthContext} from '../../context/Context';
import classNames from 'classnames';

const NavbarVerticalMenu = ({routes, location}) => {
    const [openedIndex, setOpenedIndex] = useState(null);
    const {setShowBurgerMenu} = useContext(AppContext);
    const {isAdmin} = useContext(AuthContext);
    useEffect(() => {
        let openedDropdown = null;
        routes.forEach((route, index) => {
            if (location?.pathname.indexOf(route.to) === 0) openedDropdown = index;
        });

        setOpenedIndex(openedDropdown);
        // eslint-disable-next-line
    }, []);

    const toggleOpened = (e, index) => {
        e.preventDefault();
        return setOpenedIndex(openedIndex === index ? null : index);
    };

    return routes.map((route, index) => {
        if (route.admin && !isAdmin()) {
            // admin 설정 되어있는데 user 정보가 없거나 staff가 아닌 경우 바로 리턴한다.
            return null;
        }

        if (route.category) {
            return (
                <div key={index}
                     className={classNames('nav-header mb-1 fs--2 text-800 font-weight-black noselect text-nowrap', {
                         'mt-2': !route.isFirst,
                         'mt-1': route.isFirst,
                     })}>
                    {route.category}
                </div>
            );
        }

        if (!route.children) {
            return (
                <NavItem key={index}>
                    <NavLink className="nav-link" {...{to: route.to, admin: ''}} onClick={() => setShowBurgerMenu(false)}>
                        <NavbarVerticalMenuItem route={route}/>
                    </NavLink>
                </NavItem>
            );
        }

        return (
            <NavItem key={index}>
                <BootstrapNavLink
                    onClick={e => toggleOpened(e, index)}
                    className="dropdown-indicator cursor-pointer noselect"
                    aria-expanded={openedIndex === index}
                >
                    <NavbarVerticalMenuItem route={route}/>
                </BootstrapNavLink>
                <Collapse isOpen={openedIndex === index}>
                    <Nav>
                        <NavbarVerticalMenu routes={route.children} location={location}/>
                    </Nav>
                </Collapse>
            </NavItem>
        );
    });
};

NavbarVerticalMenu.propTypes = {
    routes: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired
};

export default withRouter(NavbarVerticalMenu);