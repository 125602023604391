import {appUrls} from "./urls";
import {faTiktok} from "@fortawesome/free-brands-svg-icons/faTiktok";
import {faXiao} from "./helpers/customIcons";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {faTools} from "@fortawesome/free-solid-svg-icons/faTools";

export const AdminRoutes = {
    name: '어드민',
    to: appUrls().admin,
    admin: true,
    exact: true,
    icon: faTools,
    children : [
        {
            category: '관리',
            isFirst: true,  // 첫번째 헤더는 mt를 적용하지 않는다.
        },
        {
            to: appUrls().users,
            name: '유저 관리'
        },
        {
            to: appUrls().logs,
            name: '크롤러 로그'
        },
        // {
        //     category: '캠페인',
        // },
        // {
        //     to: appUrls().logs,
        //     name: '캠페인 관리'
        // }
    ]
}

export const XHSInfluencerRoutes = {
    name: '샤홍슈',
    to: appUrls().XHSPrefix,
    icon: faXiao,
    children: [
        {
            category: '랭킹',
            isFirst: true,  // 첫번째 헤더는 mt를 적용하지 않는다.
        },
        {
            to: appUrls().XHSHotContents,
            name: '컨텐츠 랭킹'
        },
        {
            to: appUrls().XHSHotInfluencers,
            name: '인플루언서 랭킹'
        },
        {
            category: '분석',
            isFirst: true,  // 첫번째 헤더는 mt를 적용하지 않는다.
        },
        {
            to: appUrls().XHSInfluencers,
            name: '인플루언서 탐색'
        },
        // {
        //     to: appUrls().XHSMCNs,
        //     name: 'MCN 탐색',
        //     admin: true,
        // },
        {
            to: appUrls().XHSBrandAnalysis,
            name: '브랜드 분석'
        },
        // {
        //     to: appUrls().XHSKeywordAnalysis,
        //     name: '키워드 트렌드 분석'
        // },
        {
            to: appUrls().XHSNoteTracker,
            name: '컨텐츠 모니터링',
        },
        {
            category: '캠페인',
        },
        {
            to: appUrls().XHSCampaignLineup,
            name: '나의 캠페인 라인업'
        },
        {
            to: appUrls().XHSCampaignRequest,
            name: '캠페인 신청',
            // admin: true,
        },
        {
            to: appUrls().XHSCampaigns,
            exact: true,
            name: '캠페인 대시보드',
        },
    ]
};

export const SupportRoutes = {
    name: '고객지원',
    to: '/support',
    icon: faInfoCircle,
    children: [
        // {
        //     to: appUrls().supportFAQ,
        //     name: '자주 묻는 질문',
        //     isFirst: true,
        // },
        {
            to: appUrls().supportAsk,
            name: '문의하기',
            isFirst: true,
        },
    ]
};

export const DouyinRoutes = {
    name: '도우인',
    to: '/douyin',
    icon: faTiktok,
    children: [
        {
            category: '랭킹',
            isFirst: true,  // 첫번째 헤더는 mt를 적용하지 않는다.
        },
        {
            to: appUrls().DouyinHotContents,
            name: '컨텐츠 랭킹'
        },
        {
            to: appUrls().DouyinHotInfluencers,
            name: "인플루언서 랭킹",
        },
        {
            category: '분석',
            isFirst: true,  // 첫번째 헤더는 mt를 적용하지 않는다.
        },
        {
            to: appUrls().DouyinInfluencers,
            name: '인플루언서 탐색'
        },
        {
            to: appUrls().DouyinVideoTracker,
            name: '컨텐츠 모니터링',
        },
        {
            category: '캠페인',
        },
        {
            to: appUrls().DouyinCampaignLineup,
            name: '나의 캠페인 라인업'
        },
        {
            to: appUrls().DouyinCampaignRequest,
            name: '캠페인 신청',
            // admin: true,
        },
        {
            to: appUrls().DouyinCampaigns,
            exact: true,
            name: '캠페인 대시보드',
        },
    ]
};

export default [
    // HomeRoutes,
    AdminRoutes,
    XHSInfluencerRoutes,
    DouyinRoutes,
    SupportRoutes
];
