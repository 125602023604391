import moment from "moment";
import React from "react";
import { appUrls } from "../urls";

export const isIterableArray = (array) =>
	Array.isArray(array) && !!array.length;

//===============================
// Store
//===============================
export const getItemFromStore = (key, defaultValue, store = localStorage) =>
	JSON.parse(store.getItem(key)) || defaultValue;
export const setItemToStore = (key, payload, store = localStorage) =>
	store.setItem(key, JSON.stringify(payload));
export const getStoreSpace = (store = localStorage) =>
	parseFloat(
		(
			escape(encodeURIComponent(JSON.stringify(store))).length /
			(1024 * 1024)
		).toFixed(2)
	);

//===============================
// Moment
//===============================
export const getDuration = (startDate, endDate) => {
	if (!moment.isMoment(startDate))
		throw new Error(
			`Start date must be a moment object, received ${typeof startDate}`
		);
	if (endDate && !moment.isMoment(endDate))
		throw new Error(
			`End date must be a moment object, received ${typeof startDate}`
		);

	return `${startDate.format("ll")} - ${
		endDate ? endDate.format("ll") : "Present"
	} • ${startDate.from(endDate || moment(), true)}`;
};

export const numberFormatter = (number, fixed = 2) => {
	// Nine Zeroes for Billions
	return Math.abs(Number(number)) >= 1.0e9
		? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + "B"
		: // Six Zeroes for Millions
		Math.abs(Number(number)) >= 1.0e6
		? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + "M"
		: // Three Zeroes for Thousands
		Math.abs(Number(number)) >= 1.0e3
		? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + "K"
		: Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Custom Helper Functions
//===============================

// React 개발 버전 / 프로덕션 빌드 구분
export const isDev = () => {
	return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
};

// 모바일 에이전트 구분
export function isMobile() {
	try {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		);
	} catch (e) {
		return false;
	}
}

export const chunk = (arr, len) => {
	let chunks = [],
		i = 0,
		n = arr.length;
	while (i < n) {
		chunks.push(arr.slice(i, (i += len)));
	}
	return chunks;
};

export const checkRedirect = (data, redirect, main) => {
	if (data === undefined || data.status_code === 404) {
		redirect.setRedirectUrl(appUrls().error404);
		redirect.setRedirect(true);
	} else {
		main();
	}
};

export const truncateNumber = (number) => {
	const num = Math.abs(Number(number));
	if (num > 100000000) {
		return (num / 100000000).toFixed(1).replace(/[.][0]/g, "") + "억";
	} else if (num > 10000) {
		const formatted = (num / 10000).toFixed(1);
		const [integerPart, decimalPart] = formatted.split(".");
		const formattedIntegerPart = Number(integerPart).toLocaleString();
		return `${formattedIntegerPart}${
			decimalPart ? "." + decimalPart : ""
		}만`;
	} else {
		return intComma(num);
	}
};

export const formatToWonUnit = (amount) => {
    return formatNumberWithKoreanUnit(amount) + '원';
};

export const formatNumberWithKoreanUnit = (number, decimalPlaces = 1) => {
    if (!number) return '0';
    
    const num = Math.abs(Number(number));
    const decimalFormat = Math.pow(10, decimalPlaces);
    
    if (num >= 100000000) { // 억 단위
        const value = Math.round((num / 100000000) * decimalFormat) / decimalFormat;
        const [wholePart, decimalPart] = value.toFixed(decimalPlaces).split('.');
        const formattedWhole = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return decimalPart && Number(decimalPart) !== 0 
            ? `${formattedWhole}.${decimalPart}억`
            : `${formattedWhole}억`;
    }
    
    if (num >= 10000) { // 만 단위
        const value = Math.round((num / 10000) * decimalFormat) / decimalFormat;
        const [wholePart, decimalPart] = value.toFixed(decimalPlaces).split('.');
        const formattedWhole = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return decimalPart && Number(decimalPart) !== 0
            ? `${formattedWhole}.${decimalPart}만`
            : `${formattedWhole}만`;
    }
    
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatNumberWithCustomComma = (number, commaInterval = 3, decimalPlaces = 0) => {
    if (!number && number !== 0) return '';
    
    const [wholePart, decimalPart] = Math.abs(Number(number))
        .toFixed(decimalPlaces)
        .toString()
        .split('.');
    
    const formattedWholePart = wholePart.toString().replace(new RegExp(`\\B(?=(\\d{${commaInterval}})+(?!\\d))`, 'g'), ',');
    
    const sign = number < 0 ? '-' : '';
    
    return decimalPart 
        ? `${sign}${formattedWholePart}.${decimalPart}`
        : `${sign}${formattedWholePart}`;
};

export const formatNumberWithEnglishUnit = (number, decimalPlaces = 1) => {
    if (!number && number !== 0) return '0';
    
    const num = Math.abs(Number(number));
    const decimalFormat = Math.pow(10, decimalPlaces);
    
    if (num >= 1000000000) { // Billion (G)
        const value = Math.round((num / 1000000000) * decimalFormat) / decimalFormat;
        const [wholePart, decimalPart] = value.toFixed(decimalPlaces).split('.');
        return decimalPart && Number(decimalPart) !== 0 
            ? `${wholePart}.${decimalPart}G`
            : `${wholePart}G`;
    }
    
    if (num >= 1000000) { // Million (M)
        const value = Math.round((num / 1000000) * decimalFormat) / decimalFormat;
        const [wholePart, decimalPart] = value.toFixed(decimalPlaces).split('.');
        return decimalPart && Number(decimalPart) !== 0
            ? `${wholePart}.${decimalPart}M` 
            : `${wholePart}M`;
    }
    
    if (num >= 1000) { // Thousand (K)
        const value = Math.round((num / 1000) * decimalFormat) / decimalFormat;
        const [wholePart, decimalPart] = value.toFixed(decimalPlaces).split('.');
        return decimalPart && Number(decimalPart) !== 0
            ? `${wholePart}.${decimalPart}K`
            : `${wholePart}K`;
    }
    
    return number.toString();
};


export const intComma = (x, useNegative = false) => {
	if (x === "-") {
		return "-";
	}
	if (x === null) {
		return null;
	} else {
		switch (true) {
			case x >= 0:
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			case x < 0:
				return (
					(useNegative ? "-" : "") +
					x
						.toString()
						.replace("-", "")
						.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
				);
			default:
				return null;
		}
	}
};

export const intCommaNew = (x, useNegative = false) => {
	if (x === "-") {
		return "-";
	}
	if (x === null) {
		return 0;
	}
	const num = Math.abs(Number(x));
	if (num === 10000000) {
		return `1억`;
	} else if (num === 1000000) {
		return `100만`;
	} else if (num === 100000) {
		return `10만`;
	} else if (num === 10000) {
		return `1만`;
	} else if (num === 1000) {
		return `1,000+`;
	} else if (num === 100) {
		return `100+`;
	} else if (num === 10) {
		return `10+`;
	} else if (x === 0) {
		return "0";
	}

	if (num > 100000000) {
		return (num / 100000000).toFixed(1).replace(/[.][0]/g, "") + "억";
	} else if (num > 10000) {
		const formatted = (num / 10000).toFixed(1).replace(/[.][0]/g, "");
		const formattedIntegerPart = Number(formatted).toLocaleString();
		return `${formattedIntegerPart}만`;
	} else {
		return intComma(num);
	}
};
export const phoneNumFormatter = (str) => {
	return str.replace(
		/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
		"$1-$2-$3"
	);
};

export const ISODateToString = (ISOString, type = null) => {
	let date;
	if (ISOString === "NOW") {
		date = new Date();
	} else {
		date = new Date(ISOString + "+09:00"); // Chrome과 Safari에서 동일한 결과를 얻기 위해 필요하다.
	}
	const dateData = {
		year: date.getFullYear(),
		month: date.getMonth() + 1,
		day: date.getDate(),
		hour: date.getHours(),
		minute:
			date.getMinutes() < 10
				? "0" + date.getMinutes()
				: date.getMinutes(),
		second:
			date.getSeconds() < 10
				? "0" + date.getSeconds()
				: date.getSeconds(),
	};

	let returnString;
	switch (type) {
		case null:
			returnString = `${dateData.year}년 ${dateData.month}월 ${
				dateData.day
			}일 ${dateData.hour}:${dateData.minute}`;
			break;
		case "DATE":
			returnString = `${dateData.year}.${dateData.month}.${dateData.day}`;
			break;
		case "LOG":
			returnString = `${dateData.year}-${dateData.month}-${
				dateData.day
			} ${dateData.hour}:${dateData.minute}:${dateData.second} `;
			break;
		case "DATE2":
			returnString = `${dateData.year}년 ${dateData.month}월 ${
				dateData.day
			}일`;
			break;
		default:
			returnString = "type 파라미터가 유효하지 않습니다.";
			break;
	}

	return ISOString ? returnString : null;
};

export const ISODateToStringNormal = (ISOString) => {
	if (!ISOString) return "";
	const date = new Date(ISOString);
	return moment(date).format("YYYY-MM-DD") || ISOString;
};
export const getPaginationString = ({
	sizePerPage,
	totalSize,
	page,
	unit = "개",
}) => {
	const quotient = totalSize / (sizePerPage * page);
	const remainder = totalSize % sizePerPage;

	const from = sizePerPage * (page - 1) + 1;
	const to = quotient >= 1 ? sizePerPage * page : from + remainder - 1;
	return `총 ${totalSize}${unit} 중 ${from}-${to}번 째 (${page} 페이지)`;
};

export const downloadCSV = (data, filename, type) => {
	const file = new Blob([data], { type: type });
	if (window.navigator.msSaveOrOpenBlob)
		// IE10+
		window.navigator.msSaveOrOpenBlob(file, filename);
	else {
		// Others
		const a = document.createElement("a"),
			url = URL.createObjectURL(file);
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		setTimeout(function() {
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		}, 0);
	}
};

export function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
}

//===============================
// Colors
//===============================

export const hashCode = (str) => {
	// java String#hashCode
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
};

export const intToRGB = (i) => {
	const c = (i & 0x00ffffff).toString(16).toUpperCase();

	return "00000".substring(0, 6 - c.length) + c;
};

export const hexToRgb = (hexValue) => {
	let hex;
	hexValue.indexOf("#") === 0
		? (hex = hexValue.substring(1))
		: (hex = hexValue);
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
		hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
	);
	return result
		? [
				parseInt(result[1], 16),
				parseInt(result[2], 16),
				parseInt(result[3], 16),
		  ]
		: null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) =>
	`rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
	"#2c7be5",
	"#00d97e",
	"#e63757",
	"#39afd1",
	"#fd7e14",
	"#02a8b5",
	"#727cf5",
	"#6b5eae",
	"#ff679b",
	"#f6c343",
];

export const themeColors = {
	primary: "#8242ff",
	secondary: "#748194",
	success: "#36b37e",
	info: "#27bcfd",
	warning: "#f5803e",
	danger: "#e63757",
	light: "#f9fafd",
	dark: "#0b1727",
};

export const gradientPrimary = {
	100: "#d1e6ff",
	300: "#a3cef1",
	500: "#6ea1db",
	700: "#4780cc",
	900: "#0069cc",
};

export const grays = {
	white: "#fff",
	100: "#f9fafd",
	200: "#edf2f9",
	300: "#d8e2ef",
	400: "#b6c1d2",
	500: "#9da9bb",
	600: "#748194",
	700: "#5e6e82",
	800: "#4d5969",
	900: "#344050",
	1000: "#232e3c",
	1100: "#0b1727",
	1200: "#d8e2efcc",
	black: "#000",
};

export const darkGrays = {
	white: "#fff",
	1100: "#f9fafd",
	1000: "#edf2f9",
	900: "#d8e2ef",
	800: "#b6c1d2",
	700: "#9da9bb",
	600: "#748194",
	500: "#5e6e82",
	400: "#4d5969",
	300: "#344050",
	200: "#232e3c",
	100: "#0b1727",
	black: "#000",
};

export const getGrays = (isDark) => (isDark ? darkGrays : grays);

export const rgbColors = colors.map((color) => rgbColor(color));
export const rgbaColors = colors.map((color) => rgbaColor(color));

//===============================
// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
	top: pos[1] - size.contentSize[1] - 10,
	left: pos[0] - size.contentSize[0] / 2 - 50,
});

//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) =>
	(base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
	cart.reduce((accumulator, currentValue) => {
		const { id, quantity } = currentValue;
		const { price, sale } = baseItems.find((item) => item.id === id);
		return accumulator + calculateSale(price, sale) * quantity;
	}, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
	const noOfpages = Math.ceil(totalSize / sizePerPage);
	const array = [];
	let pageNo = 1;
	while (pageNo <= noOfpages) {
		array.push(pageNo);
		pageNo = pageNo + 1;
	}
	return array;
};

export const getPaginationArrayTruncated = (
	currentPage,
	totalSize,
	maxLength,
	sizePerPage
) => {
	if (maxLength % 2 === 0) {
		throw Error;
	}

	const noOfpages = Math.ceil(totalSize / sizePerPage);

	if (noOfpages <= maxLength) {
		return range(1, noOfpages);
	}

	const side = (maxLength - 1) / 2;
	let minPage = currentPage - side < 1 ? 1 : currentPage - side;
	let maxPage =
		currentPage + side > noOfpages ? noOfpages : currentPage + side;

	if (maxPage - minPage + 1 < maxLength) {
		maxPage === noOfpages
			? (minPage = maxPage - maxLength + 1)
			: (maxPage = minPage + maxLength - 1);
	}

	return range(minPage, maxPage);
};

function range(start, end) {
	const arr = [];
	const length = end - start;
	for (let i = 0; i <= length; i++) {
		arr[i] = start;
		start++;
	}
	return arr;
}

export const capitalize = (str) =>
	(str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, " ");

export const checkObjEmpty = (data) => {
	if (data && Object.keys(data)?.length === 0) {
		return true;
	}
	return false;
};
export const replaceURL = (URL) => {
	const loginUrl = appUrls().login;
	const params = new URLSearchParams({ prev: URL }).toString();
	const redirectUrl = `${loginUrl}?${params}`;
	return redirectUrl;
};
